import React, { useMemo, useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import './SalesGraph.css';

type Period = '7d' | '14d' | '30d' | '90d' | '180d' | '1y' | 'som';
type Aggregation = 'daily' | 'weekly' | 'monthly';

interface SalesGraphProps {
  orders: Array<{
    dateObject: Date;
    priceNumber: number;
    frameCount?: number;
  }>;
}

type MetricType = 'price' | 'orders' | 'frames';

const SalesGraph: React.FC<SalesGraphProps> = ({ orders }) => {
  const [selectedPeriod, setSelectedPeriod] = useState<Period>('som');
  const [aggregation, setAggregation] = useState<Aggregation>('daily');

  // Update period when switching to monthly view
  const setAggregationWithPeriodUpdate = (newAggregation: Aggregation) => {
    setAggregation(newAggregation);
    if (newAggregation === 'monthly') {
      // Force 90d view for monthly aggregation
      setSelectedPeriod('90d');
    }
  };
  const [selectedMetrics, setSelectedMetrics] = useState<MetricType[]>(['price']);

  const processData = (period: Period, data: typeof orders, metric: MetricType) => {
    // Get current date in local timezone
    const now = new Date();
    // Set to midnight to avoid timezone issues
    now.setHours(0, 0, 0, 0);
    
    // Create today's date at end of day in local timezone
    const today = new Date(now);
    today.setHours(23, 59, 59, 999);
    
    let startDate: Date;
    if (period === 'som') {
      // First day of current month
      startDate = new Date(now.getFullYear(), now.getMonth(), 1);
      startDate.setHours(0, 0, 0, 0);
    } else {
      const days = period === '1y' ? 365 : parseInt(period.replace('d', ''));
      startDate = new Date(now);
      startDate.setDate(now.getDate() - days + 1);
      startDate.setHours(0, 0, 0, 0);
    }

    // Create a map of all dates in the range
    const dateMap: Record<string, number> = {};
    const tempDate = new Date(startDate);
    while (tempDate <= today) {
      // Format date in local timezone
      const localDate = new Date(tempDate);
      const year = localDate.getFullYear();
      const month = String(localDate.getMonth() + 1).padStart(2, '0');
      const day = String(localDate.getDate()).padStart(2, '0');
      const dateKey = `${year}-${month}-${day}`;
      dateMap[dateKey] = 0;
      tempDate.setDate(tempDate.getDate() + 1);
    }

    // Fill in sales data
    data.forEach(order => {
      if (order.dateObject && order.dateObject instanceof Date && !isNaN(order.dateObject.getTime())) {
        // Get local date without time component
        const orderDate = new Date(order.dateObject);
        orderDate.setHours(0, 0, 0, 0);

        if (orderDate >= startDate && orderDate <= today) {
          const year = orderDate.getFullYear();
          const month = String(orderDate.getMonth() + 1).padStart(2, '0');
          const day = String(orderDate.getDate()).padStart(2, '0');
          const dateKey = `${year}-${month}-${day}`;
          if (metric === 'price') {
            dateMap[dateKey] = (dateMap[dateKey] || 0) + order.priceNumber;
          } else if (metric === 'orders') {
            dateMap[dateKey] = (dateMap[dateKey] || 0) + 1;
          } else if (metric === 'frames') {
            dateMap[dateKey] = (dateMap[dateKey] || 0) + (order.frameCount || 0);
          }
        }
      }
    });

    if (aggregation === 'weekly') {
      const weeklyMap: Record<string, number> = {};
      Object.entries(dateMap).forEach(([date, amount]) => {
        const dateParts = date.split('-').map(Number);
        const weekStart = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());
        const year = weekStart.getFullYear();
        const month = String(weekStart.getMonth() + 1).padStart(2, '0');
        const day = String(weekStart.getDate()).padStart(2, '0');
        const weekKey = `${year}-${month}-${day}`;
        weeklyMap[weekKey] = (weeklyMap[weekKey] || 0) + amount;
      });
      return Object.entries(weeklyMap)
        .map(([date, amount]) => ({ date, amount }))
        .sort((a, b) => a.date.localeCompare(b.date));
    }

    if (aggregation === 'monthly') {
      const monthlyMap: Record<string, number> = {};
      Object.entries(dateMap).forEach(([date, amount]) => {
        const [year, month] = date.split('-');
        const monthKey = `${year}-${month}-01`;
        monthlyMap[monthKey] = (monthlyMap[monthKey] || 0) + amount;
      });
      return Object.entries(monthlyMap)
        .map(([date, amount]) => ({ date, amount }))
        .sort((a, b) => a.date.localeCompare(b.date));
    }

    return Object.entries(dateMap)
      .map(([date, amount]) => ({ date, amount }))
      .sort((a, b) => a.date.localeCompare(b.date));
  };

  const graphData = useMemo(() => {
    // Create a map to store combined data
    const combinedData = new Map<string, any>();

    // Process data for each selected metric
    selectedMetrics.forEach(metric => {
      const metricData = processData(selectedPeriod, orders, metric);
      
      metricData.forEach(({ date, amount }) => {
        if (!combinedData.has(date)) {
          combinedData.set(date, { date });
        }
        combinedData.get(date)[`${metric}Amount`] = amount;
      });
    });

    // Convert map to array and sort by date
    return Array.from(combinedData.values())
      .sort((a, b) => a.date.localeCompare(b.date));
  }, [orders, selectedPeriod, aggregation, selectedMetrics]);

  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    if (aggregation === 'monthly') {
      return new Intl.DateTimeFormat('he-IL', { 
        month: 'short',
        year: 'numeric'
      }).format(date);
    }
    if (aggregation === 'weekly') {
      return new Intl.DateTimeFormat('he-IL', { 
        month: 'numeric',
        day: 'numeric'
      }).format(date) + ' (שבוע)';
    }
    return new Intl.DateTimeFormat('he-IL', { 
      month: 'numeric',
      day: 'numeric'
    }).format(date);
  };

  const formatValue = (value: number, metric: MetricType) => {
    if (metric === 'price') {
      return new Intl.NumberFormat('he-IL', {
        style: 'currency',
        currency: 'ILS'
      }).format(value);
    }
    return value.toLocaleString('he-IL');
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="sales-graph-tooltip">
          <p className="date">{formatDate(label)}</p>
          {payload.map((entry: any, index: number) => {
            const metric = entry.dataKey.replace('Amount', '') as MetricType;
            return (
              <p key={index} className="amount" style={{ color: entry.stroke }}>
                {entry.name}: {formatValue(entry.value, metric)}
              </p>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const toggleMetric = (metric: MetricType) => {
    setSelectedMetrics(prev => 
      prev.includes(metric)
        ? prev.filter(m => m !== metric)
        : [...prev, metric]
    );
  };

  const selectPeriod = (period: Period) => {
    setSelectedPeriod(period);
  };

  const getGraphTitle = () => {
    const periodText = selectedPeriod === 'som'
      ? 'Start of Month'
      : selectedPeriod === '1y'
        ? '1 Year'
        : selectedPeriod.replace('d', ' Days');
    
    const aggregationText = {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly'
    }[aggregation];

    const metricsText = selectedMetrics.map(m => 
      m === 'price' ? 'Sales' : 
      m === 'orders' ? 'Orders' : 
      'Frames'
    ).join(', ');

    return `${aggregationText} ${metricsText} (${periodText})`;
  };

  return (
    <div className="sales-graph-container">
      <div className="sales-graph-header">
        <h3 className="sales-graph-title">{getGraphTitle()}</h3>
        <div className="sales-graph-controls">
          <div className="toggle-group">
            <button 
              className={`toggle-button ${aggregation === 'daily' ? 'active' : ''}`}
              onClick={() => setAggregationWithPeriodUpdate('daily')}
            >
              Daily
            </button>
            <button 
              className={`toggle-button ${aggregation === 'weekly' ? 'active' : ''}`}
              onClick={() => setAggregationWithPeriodUpdate('weekly')}
            >
              Weekly
            </button>
            <button 
              className={`toggle-button ${aggregation === 'monthly' ? 'active' : ''}`}
              onClick={() => setAggregationWithPeriodUpdate('monthly')}
            >
              Monthly
            </button>
          </div>
          <div className="toggle-group periods-group">
            <button 
              className={`toggle-button ${selectedPeriod === 'som' ? 'active' : ''}`}
              onClick={() => selectPeriod('som')}
              disabled={aggregation === 'monthly'}
              style={{ opacity: aggregation === 'monthly' ? 0.5 : 1 }}
            >
              SOM
            </button>
            <button 
              className={`toggle-button ${selectedPeriod === '7d' ? 'active' : ''}`}
              onClick={() => selectPeriod('7d')}
              disabled={aggregation === 'monthly'}
              style={{ opacity: aggregation === 'monthly' ? 0.5 : 1 }}
            >
              7D
            </button>
            <button 
              className={`toggle-button ${selectedPeriod === '14d' ? 'active' : ''}`}
              onClick={() => selectPeriod('14d')}
              disabled={aggregation === 'monthly'}
              style={{ opacity: aggregation === 'monthly' ? 0.5 : 1 }}
            >
              14D
            </button>
            <button 
              className={`toggle-button ${selectedPeriod === '30d' ? 'active' : ''}`}
              onClick={() => selectPeriod('30d')}
              disabled={aggregation === 'monthly'}
              style={{ opacity: aggregation === 'monthly' ? 0.5 : 1 }}
            >
              30D
            </button>
            <button 
              className={`toggle-button ${selectedPeriod === '90d' ? 'active' : ''}`}
              onClick={() => selectPeriod('90d')}
            >
              90D
            </button>
            <button 
              className={`toggle-button ${selectedPeriod === '180d' ? 'active' : ''}`}
              onClick={() => selectPeriod('180d')}
            >
              180D
            </button>
            <button 
              className={`toggle-button ${selectedPeriod === '1y' ? 'active' : ''}`}
              onClick={() => selectPeriod('1y')}
            >
              1Y
            </button>
          </div>
        </div>
      </div>
      <div className="sales-graph-metrics">
        <div 
          className={`metric-toggle ${selectedMetrics.includes('price') ? 'active' : ''}`}
          onClick={() => toggleMetric('price')}
          style={{ borderColor: '#008060' }}
        >
          <div className="metric-circle" style={{ backgroundColor: selectedMetrics.includes('price') ? '#008060' : 'transparent' }} />
          <span>Price</span>
        </div>
        <div 
          className={`metric-toggle ${selectedMetrics.includes('orders') ? 'active' : ''}`}
          onClick={() => toggleMetric('orders')}
          style={{ borderColor: '#2C5282' }}
        >
          <div className="metric-circle" style={{ backgroundColor: selectedMetrics.includes('orders') ? '#2C5282' : 'transparent' }} />
          <span>Orders</span>
        </div>
        <div 
          className={`metric-toggle ${selectedMetrics.includes('frames') ? 'active' : ''}`}
          onClick={() => toggleMetric('frames')}
          style={{ borderColor: '#9B2C2C' }}
        >
          <div className="metric-circle" style={{ backgroundColor: selectedMetrics.includes('frames') ? '#9B2C2C' : 'transparent' }} />
          <span>Frames</span>
        </div>
      </div>
      <div className="sales-graph">
        <ResponsiveContainer width="100%" height={150}>
          <AreaChart
            data={graphData}
            margin={{ top: 10, right: 60, left: 60, bottom: 0 }}
          >
            <CartesianGrid 
              strokeDasharray="3 3" 
              vertical={false}
              stroke="#E5E5E5"
            />
            <XAxis 
              dataKey="date" 
              tickFormatter={formatDate}
              stroke="#637381"
              tick={{ fontSize: 13, fontWeight: 500 }}
              axisLine={{ stroke: '#E5E5E5' }}
              tickLine={false}
            />
            {selectedMetrics.map((metric, index) => {
              const colors = {
                price: '#008060',
                orders: '#2C5282',
                frames: '#9B2C2C'
              };
              const yAxisId = `${metric}Axis`;
              return (
                <React.Fragment key={`${metric}-axis-area`}>
                  <YAxis
                    yAxisId={yAxisId}
                    orientation={index === 0 ? 'left' : 'right'}
                    stroke={colors[metric]}
                    tick={{ fontSize: 13, fontWeight: 500 }}
                    tickFormatter={(value) => formatValue(value, metric)}
                    axisLine={{ stroke: colors[metric] }}
                    tickLine={false}
                  />
                  <defs>
                    <linearGradient id={`color${metric}`} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={colors[metric]} stopOpacity={0.2}/>
                      <stop offset="95%" stopColor={colors[metric]} stopOpacity={0.05}/>
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey={`${metric}Amount`}
                    yAxisId={yAxisId}
                    stroke={colors[metric]}
                    strokeWidth={2.5}
                    fill={`url(#color${metric})`}
                    animationDuration={300}
                    name={metric === 'price' ? 'Price' : 
                         metric === 'orders' ? 'Orders' : 
                         'Frames'}
                  />
                </React.Fragment>
              );
            })}
            <Tooltip content={<CustomTooltip />} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SalesGraph;
